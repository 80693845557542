// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from 'next/dynamic';

export const components = {
  divider: dynamic(() => import('./Divider')),
  features: dynamic(() => import('./Features')),
  heading: dynamic(() => import('./Heading')),
  image: dynamic(() => import('./Image')),
  myths_vs_facts: dynamic(() => import('./MythsVsFacts')),
  notification: dynamic(() => import('./Notification')),
  ordered_list: dynamic(() => import('./OrderedList')),
  rich_text: dynamic(() => import('./RichText')),
  table: dynamic(() => import('./Table')),
};
